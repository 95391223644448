<template>
  <td>
    <slot>cell content</slot>
  </td>
</template>

<script>
export default {
  name: 'TableColumn',
}
</script>

<style scoped lang="scss">
  @import "../styles/constants.module.scss";

</style>

